export const amapColumns = () => {
  return [
    {
      title: '序号',
      width: '80px',
      align: 'center',
      scopedSlots: { customRender: '_index' },
    },
    {
      title: '名称',
      align: 'center',
      dataIndex: 'name',
    },
    {
      title: '地图Id',
      width: '80px',
      align: 'center',
      dataIndex: 'id',
    },
    // {
    //   title: '所属单位',
    //   align: 'center',
    //   dataIndex: 'organization.name',
    // },
    // {
    //   title: '描述',
    //   align: 'center',
    //   dataIndex: 'description'
    // },
    {
      title: '启用',
      align: 'center',
      dataIndex: 'enable',
      scopedSlots: { customRender: 'enableSlot' },
    },
    {
      title: '中心点',
      align: 'center',
      dataIndex: 'center',
    },
    {
      title: '默认显示级别',
      align: 'center',
      dataIndex: 'zoom',
    },
    {
      title: '展示模式',
      align: 'center',
      dataIndex: 'viewMode',
      customRender: (text, record) => {
        const time = `${record.viewMode ? record.viewMode : '-'}` //es6写法
        return time
      },
    },
    {
      title: '操作',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ]
}

export const amapFormVO = () => {
  return {
    id: '',
    name: '',
    description: '',
    organizationId: 0,
    center: '',
    enable: null,
    zoom: null,
    viewMode: '',
    dataFilterType: 0,
    verifyViewAuth: true,
    kpiTypes: [],
    fitViewType: 0,
    adcode: '',
    selectConfigJson: {
      layerSelect: {
        engineering: {
          deviceTypes: [],
          facilityTypes: [],
          depotTypes: [],
          deviceFacilityTags: []
        },
        biz: {
          bizTypes: [],
        },
      },
      leftListSelect: {
        deviceTypes: [],
        facilityTypes: [],
        depotTypes: [],
      },
    },
  }
}
