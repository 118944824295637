<template>
  <a-spin :spinning="spinShow">
    <a-form :form="form">
      <a-form-item label="名称" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['name', { rules: [{ required: true, message: '名称不能为空' }] }]" placeholder="请输入">
        </a-input>
      </a-form-item>
      <a-form-item label="描述" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-textarea v-decorator="['description']" rows="3" placeholder="请输入地图描述"></a-textarea>
      </a-form-item>
      <a-form-item label="地图中心点" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input
          v-decorator="['center', { rules: [{ required: true, message: '地图中心点不能为空' }] }]"
          placeholder="请输入地图中心点"
        >
        </a-input>
      </a-form-item>
      <a-form-item label="缩放层级" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input
          v-decorator="['zoom', { rules: [{ required: true, message: '缩放层级不能为空' }] }]"
          placeholder="请输入缩放层级"
        >
        </a-input>
      </a-form-item>
      <a-form-item label="地图FitView" :label-col="labelCol" :wrapper-col="wrapperCol" required>
        <a-radio-group
          :options="mapFitViewType"
          v-decorator="['fitViewType', { initialValue: 0, trigger: 'change' }]"
        ></a-radio-group>
      </a-form-item>
      <!--      v-if="form.getFieldValue('fitViewType') === 1"-->
      <a-form-item label="adcode" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input
          v-decorator="[
            'adcode',
            { rules: [{ required: true, message: '行政区adcode不能为空' }], initialValue: formItem.adcode },
          ]"
          placeholder="请输入行政区adcode"
        >
        </a-input>
      </a-form-item>
      <a-form-item label="展示模式" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['viewMode']" placeholder="请输入展示模式"></a-input>
      </a-form-item>
      <a-form-item label="资源过滤类型" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-radio-group
          :options="dataFilterType"
          @change="dataFilterTypeRadioChange"
          v-decorator="['dataFilterType', { initialValue: 0, trigger: 'change' }]"
        ></a-radio-group>
      </a-form-item>
      <div v-show="showOrganization">
        <a-form-item label="所属单位" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-tree-select :treeData="deptList" v-decorator="['organizationId']"></a-tree-select>
        </a-form-item>
        <a-form-item label="校验查看权限" :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-radio-group
            :options="verifyViewAuthType"
            v-decorator="['verifyViewAuth', { initialValue: true, trigger: 'change' }]"
          ></a-radio-group>
        </a-form-item>
      </div>
      <a-divider orientation="left">图层下拉</a-divider>
      <a-form-item label="工程对象" :label-col="labelCol" :wrapper-col="wrapperCol" style="margin-bottom: 0px;"/>
<!--      <a-form-item label="设备" :label-col="contentLabelCol" :wrapper-col="contentWrapperCol">-->
<!--        <a-tree-select-->
<!--          multiple-->
<!--          treeCheckable-->
<!--          :treeData="equipmentTypeTree"-->
<!--          placeholder="请选择设备类型"-->
<!--          treeDefaultExpandAll-->
<!--          v-decorator="['selectConfigJson.layerSelect.engineering.deviceTypes']"-->
<!--        ></a-tree-select>-->
<!--      </a-form-item>-->
      <a-form-item label="设备/设施" :label-col="contentLabelCol" :wrapper-col="contentWrapperCol">
        <a-select
          v-decorator="['selectConfigJson.layerSelect.engineering.deviceFacilityTags']"
          mode="multiple"
          placeholder="请选择设备/设备标签"
        >
          <a-select-option v-for="item in tagsTree" :key="item.key">
           {{item.value}}
          </a-select-option>
        </a-select>
      </a-form-item>
<!--      <a-form-item label="设施类型" :label-col="contentLabelCol" :wrapper-col="contentWrapperCol">-->
<!--        <a-tree-select-->
<!--          multiple-->
<!--          treeCheckable-->
<!--          :treeData="facilityTypeTree"-->
<!--          placeholder="请选择设施类型"-->
<!--          treeDefaultExpandAll-->
<!--          v-decorator="['selectConfigJson.layerSelect.engineering.facilityTypes']"-->
<!--        ></a-tree-select>-->
<!--      </a-form-item>-->
<!--      <a-form-item label="仓库" :label-col="contentLabelCol" :wrapper-col="contentWrapperCol">-->
<!--        <a-tree-select-->
<!--          multiple-->
<!--          treeCheckable-->
<!--          :treeData="depotTypeTree"-->
<!--          placeholder="请选择仓库类型"-->
<!--          treeDefaultExpandAll-->
<!--          v-decorator="['selectConfigJson.layerSelect.engineering.depotTypes']"-->
<!--        ></a-tree-select>-->
<!--      </a-form-item>-->
      <a-form-item
        label="业务对象"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        style="margin-bottom: 0px;"
      ></a-form-item>
      <a-form-item label="业务类型" :label-col="contentLabelCol" :wrapper-col="contentWrapperCol">
        <a-tree-select
          multiple
          treeCheckable
          :treeData="otherOptionTree"
          allowClear
          placeholder="请选择业务类型"
          v-decorator="['selectConfigJson.layerSelect.biz.bizTypes']"
        ></a-tree-select>
      </a-form-item>
<!--      <a-divider orientation="left">左侧列表下拉</a-divider>-->
<!--      <a-form-item label="设备类型" :label-col="contentLabelCol" :wrapper-col="contentWrapperCol">-->
<!--        <a-tree-select-->
<!--          multiple-->
<!--          treeCheckable-->
<!--          :treeData="equipmentTypeTree"-->
<!--          placeholder="请选择设备类型"-->
<!--          treeDefaultExpandAll-->
<!--          v-decorator="['selectConfigJson.leftListSelect.deviceTypes']"-->
<!--        ></a-tree-select>-->
<!--      </a-form-item>-->
<!--      <a-form-item label="设施类型" :label-col="contentLabelCol" :wrapper-col="contentWrapperCol">-->
<!--        <a-tree-select-->
<!--          multiple-->
<!--          treeCheckable-->
<!--          :treeData="facilityTypeTree"-->
<!--          placeholder="请选择设施类型"-->
<!--          treeDefaultExpandAll-->
<!--          v-decorator="['selectConfigJson.leftListSelect.facilityTypes']"-->
<!--        ></a-tree-select>-->
<!--      </a-form-item>-->
<!--      <a-form-item label="仓库" :label-col="contentLabelCol" :wrapper-col="contentWrapperCol">-->
<!--        <a-tree-select-->
<!--          multiple-->
<!--          treeCheckable-->
<!--          :treeData="depotTypeTree"-->
<!--          placeholder="请选择仓库类型"-->
<!--          treeDefaultExpandAll-->
<!--          v-decorator="['selectConfigJson.leftListSelect.depotTypes']"-->
<!--        ></a-tree-select>-->
<!--      </a-form-item>-->
      <a-form-item label="KPI指标" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-checkbox-group :options="metricsOptions" v-decorator="['kpiTypes', { trigger: 'change' }]"/>
      </a-form-item>
      <a-form-item label="是否启用" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-radio-group
          :options="enableType"
          v-decorator="['enable', { initialValue: true, trigger: 'change' }]"
        ></a-radio-group>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
  import { amapFormVO } from './common/common'
  import SERVICE_URLS from '@/api/service.url'

  export default {
    name: 'MapInfoForm',
    props: {
      type: {
        type: String,
        default: 'add'
      }
    },
    data () {
      return {
        enableType: [
          { value: true, label: '启用' },
          { value: false, label: '禁用' }
        ],
        dataFilterType: [
          { value: 0, label: '按数据权限' },
          { value: 1, label: '按部门' }
        ],
        mapFitViewType: [
          { value: 0, label: '按覆盖物' },
          { value: 1, label: '按行政区' }
        ],
        showOrganization: false,
        verifyViewAuthType: [
          { value: true, label: '是' },
          { value: false, label: '否' }
        ],
        deptList: [],
        labelCol: { span: 5 },
        wrapperCol: { span: 17 },
        contentLabelCol: { span: 7 },
        contentWrapperCol: { span: 15 },
        spinShow: false,
        formItem: amapFormVO(),
        form: this.$form.createForm(this),
        indeterminate: true,
        checkAll: false,
        metricsOptions: [
          { label: '设备监测指标', value: 'DEVICE_METRICS' },
          { label: '巡检指标', value: 'INSPECT_METRICS' }
        ],
        deviceCategoryTree: [],
        equipmentTypeTree: [],
        facilityTypeTree: [],
        depotTypeTree: [{ title: '仓库', value: 0 }],
        otherOptionTree: [
          { title: '巡检', value: 0, key: 'INSPECT' },
          { title: '车辆', value: 1, key: 'CAR' },
        ],
        tagsTree: []
      }
    },
    computed: {
      //获取当前单位
      currentOrg () {
        return this.$store.getters.currentOrg
      },
      currentUser () {
        return this.$store.getters.currentUser
      }
    },
    created () {
      this.loadDept()
      this.loadEquipmentTypeTree()
      this.loadFacilityTypeTree()
      this.loadTagsTree()
    },
    methods: {
      /**
       * 加载设施类型
       */
      loadFacilityTypeTree() {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.facilityApi.facilityTypeTree,
          noTips: true,
          success: (data) => {
            this.$nextTick(() => {
              this.facilityTypeTree = data.body
            })
          }
        })
      },
      loadEquipmentTypeTree () {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentTypeApi.search,
          noTips: true,
          success: (data) => {
            this.equipmentTypeTree.push(data.body)
          }
        })
      },
      loadTagsTree () {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.monitoringPoint.getTags,
          noTips: true,
          success: (data) => {
            this.tagsTree = data.body
          }
        })
      },
      loadDept () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.deptList = data.body
          }
        })
      },
      setVOFields (values) {
        Object.assign(this.formItem, values)
      },
      setFields (values) {
        Object.assign(this.formItem, values)
        this.form.setFieldsValue({
          name: this.formItem.name,
          organizationId: this.formItem.organizationId,
          description: this.formItem.description,
          center: this.formItem.center,
          zoom: this.formItem.zoom,
          viewMode: this.formItem.viewMode,
          enable: this.formItem.enable,
          dataFilterType: this.formItem.dataFilterType,
          verifyViewAuth: this.formItem.verifyViewAuth,
          kpiTypes: this.formItem.kpiTypes,
          fitViewType: this.formItem.fitViewType,
          adcode: this.formItem.adcode,
          // 'selectConfigJson.layerSelect.engineering.deviceTypes': this.formItem.selectConfigJson.layerSelect.engineering
          //   .deviceTypes,
          'selectConfigJson.layerSelect.engineering.deviceFacilityTags': this.formItem.selectConfigJson.layerSelect.engineering
            .deviceFacilityTags,
          // 'selectConfigJson.layerSelect.engineering.facilityTypes': this.formItem.selectConfigJson.layerSelect.engineering
          //   .facilityTypes,
          // 'selectConfigJson.layerSelect.engineering.depotTypes': this.formItem.selectConfigJson.layerSelect.engineering
          //   .depotTypes,
          'selectConfigJson.layerSelect.biz.bizTypes': this.formItem.selectConfigJson.layerSelect.biz.bizTypes,
          // 'selectConfigJson.leftListSelect.deviceTypes': this.formItem.selectConfigJson.leftListSelect.deviceTypes,
          // 'selectConfigJson.leftListSelect.facilityTypes': this.formItem.selectConfigJson.leftListSelect.facilityTypes,
          // 'selectConfigJson.leftListSelect.depotTypes': this.formItem.selectConfigJson.leftListSelect.depotTypes
        })
        // this.$nextTick(() => {
        //   if (this.formItem.fitViewType === 1) {
        //     this.form.setFieldsValue({
        //       adcode: this.formItem.adcode
        //     })
        //   }
        // })
      },
      loadData (id) {
        this.spinShow = true
        this.$http(this, {
          url: SERVICE_URLS.mapInfo.view,
          params: {
            id: id
          },
          noTips: true,
          success: (data) => {
            this.$nextTick(() => {
              this.setFields(data.body)
              this.showOrganization = this.formItem.dataFilterType === 1
            })
            this.spinShow = false
          }
        })
      },
      submitAdd () {
        this.form.validateFields((err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.$http(this, {
              url: SERVICE_URLS.mapInfo.create,
              noTips: true,
              data: this.formItem,
              loading: 'spinShow',
              success: (data) => {
                this.formItem = amapFormVO()
                this.$emit('addSuccess')
                this.form.resetFields()
              },
              error: (data) => {
                this.$emit('addError')
                this.form.resetFields()
              }
            })
          }
        })
      },
      submitEdit () {
        this.form.validateFields((err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.$http(this, {
              url: SERVICE_URLS.mapInfo.update,
              data: this.formItem,
              params: {
                id: this.formItem.id
              },
              noTips: true,
              loading: 'spinShow',
              success: (data) => {
                this.$emit('editSuccess', data.body)
              },
              error: (data) => {
                this.$emit('editError')
              }
            })
          }
        })
      },
      cancel () {
      },
      dataFilterTypeRadioChange (event) {
        this.showOrganization = event.target.value === 1
      }
    }
  }
</script>

<style scoped></style>
